<template>
  <v-card>
    <v-card-title>Item Groups Discounts </v-card-title>
    <v-card-text>
      <v-form ref="editForm" v-model="valid">
        <v-row>
          <v-col cols="3">
            <v-autocomplete
              outlined
              dense
              item-text="text"
              item-value="value"
              label="Type"
              v-model="record.Type"
              :items="Type"
            ></v-autocomplete>
          </v-col>
          <v-col v-if="this.record.Type == 'S'" cols="3">
            <v-autocomplete
              outlined
              dense
              item-text="CardName"
              item-value="CardCode"
              label="Business Partner"
              v-model="record.ObjCode"
              :items="BusinessPartners"
            ></v-autocomplete>
          </v-col>
          <v-col v-if="this.record.Type == 'C'" cols="3">
            <v-autocomplete
              outlined
              dense
              item-text="GroupName"
              item-value="GroupCode"
              label="Business Partner Group"
              v-model="record.ObjCode"
              :items="BPGroups"
            ></v-autocomplete>
          </v-col>
          <v-col cols="3">
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-text-field
                  label="Valid From"
                  readonly
                  v-on="on"
                  dense
                  v-model="record.ValidFrom"
                  outlined
                ></v-text-field>
              </template>
              <v-date-picker v-model="record.ValidFrom"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="3">
            <v-menu offset-y>
              <template v-slot:activator="{ on }">
                <v-text-field
                  dense
                  label="Valid To"
                  readonly
                  v-on="on"
                  outlined
                  v-model="record.ValidTo"
                ></v-text-field>
              </template>
              <v-date-picker v-model="record.ValidTo"></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="3">
            <v-checkbox
              label="Active"
              required
              v-model="record.ValidFor"
              :true-value="'Y'"
              :false-value="'N'"
            ></v-checkbox>
          </v-col>
        </v-row>
        <template>
          <v-btn color="success" @click="addField" x-small class="my-5"
            >Add Items</v-btn
          >

          <v-row dense>
            <v-col
              cols="12"
              v-for="(field, index) in fields"
              :key="index"
              :class="{
                'd-flex': true,
                'align-items-center': true,
              }"
            >
              <v-autocomplete
                outlined
                dense
                v-model="field.ObjKey"
                label="Item Groups"
                :items="ItemGroups"
                :item-text="
                  (item) => item.ItmsGrpCod + '  -  ' + item.ItmsGrpNam
                "
                item-value="ItmsGrpCod"
              ></v-autocomplete>
              <v-text-field
                outlined
                dense
                v-model="field.Discount"
                type="number"
                label="Discount %"
              ></v-text-field>
              <v-text-field
                outlined
                dense
                v-model="field.PayFor"
                type="number"
                label="PayFor"
              ></v-text-field>
              <v-text-field
                outlined
                dense
                v-model="field.ForFree"
                label="ForFree"
                type="number"
              ></v-text-field>
              <v-text-field
                outlined
                dense
                v-model="field.UpTo"
                label="UpTo"
                type="number"
              ></v-text-field>

              <v-btn
                class="mx-2"
                small
                color="warning"
                @click="removeField(index)"
                >Remove
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" small @click="save"> Save </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  props: {
    initial: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fields: [],
      ItemGroups: [],
      BusinessPartners: [],
      BPGroups: [],
      record: {},
      valid: false,
      Type: [
        { text: "All BPs", value: "A" },
        { text: "Customer Group", value: "C" },
        { text: "Specific BP", value: "S" },
        { text: "Vendor Group", value: "V" },
      ],
      ObjectType: [
        { text: "-1", value: -1 },
        { text: "Card Payment Groups", value: 10 },
        { text: "Cards", value: 2 },
      ],
    };
  },
  watch: {
    initial: {
      handler: "init",
      immediate: true,
    },
  },
  methods: {
    init(val) {
      this.record = { ...val };
      let fields = val.edg1;
      this.fields = Array.isArray(fields) ? [...fields] : [];
    },
    addField() {
      this.fields.push({ value: "" });
    },
    removeField(index) {
      if (index >= 0 && index < this.fields.length) {
        this.fields.splice(index, 1);
      }
    },
    closeDialog() {
      this.$emit("close");
    },
    save() {
      this.record.ObjType = 52;
      let data = {
        ...this.record,
        edg1: this.fields,
      };

      this.$emit("data", data);
    },
    getItemGroups() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/itemgroup`)
        .then((res) => {
          self.ItemGroups = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getBPgroups() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/bp_group_masterdata`)
        .then((res) => {
          self.BPGroups = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
    getBpartners() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/bp_masterdata`)
        .then((res) => {
          self.BusinessPartners = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getItemGroups();
    this.getBpartners();
    this.getBPgroups();
  },
};
</script>